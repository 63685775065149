
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Pinyon+Script:wght@400&display=swap");

@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

@font-face {
    font-family: 'Pinyon Script';
}

.logoFontFamily {
    font-family: 'Pinyon Script';
    color: #D86B4E; /*#D86B4E;#ed8936*/
    font-weight: 400;
    font-size: larger;
    position:relative;
    top:5px;
}

.logoFontFamily:hover {
    color: black;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.navbarStyle {
    color: rgba(0,0,0,1) !important;
    border-bottom: 1px solid rgba(185,185,185,0.5) !important;
    background-color: rgba(255,255,255,0.5) !important;
    backdrop-filter: blur(5px) !important;
}

.navbarLogo {
    color: rgba(0,0,0,1) !important;
}

#whoweare, #whatwedo, #techstack, #career, #contact {
    scroll-margin-top: 100px;
}

.btnDisabled:disabled {
    background-color: #BCBCBC;
    color: black;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
